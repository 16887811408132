import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';



export default function FormDialog(props) {
  //props should contain a default session if EDIT props.
  // 
  const [open, setOpen] = React.useState(false);
  const topShells = [
    { label: 'Private 1x' },
    { label: 'Italian Job' },
    { label: 'Blue SIngle' },
    { label: 'Tango' },
    { label: 'HMH' },
    { label: 'HMM' },
    { label: 'River Spirit' },
    { label: 'Brandon' },
    { label: 'Seanchai' },
    { label: 'Swift' },
    { label: 'Chupa' },
  ]

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleAddOrEdit(jo) {

    // Code to handle login goes here

    props.acceptCallBack(jo)
    handleClose();

  }

  function normalizeDate(aDateString) {
    var d = new Date(aDateString.replace('.000Z', ''))
    d.setHours(0, 0, 0, 0)
    return d.toDateString()
  }
  return (
    <React.Fragment>

      <Button variant="text" onClick={handleClickOpen} color="primary">Signup</Button>


      <Dialog 
        open={open}
        onClose={handleClose}
        PaperProps={{
          
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            //       const date = formJson.date;
            handleAddOrEdit(formJson);
          },
        }}
      >
        <DialogTitle padding={0}>Signup  { props.rowingSession.Time+ "- " +  normalizeDate(props.rowingSession.Date)} </DialogTitle>
        
        <DialogContent>
          <DialogContentText>
            {props.rowingSession.Description} 
          </DialogContentText>
          
          <TextField InputProps={{ readOnly: true }} fullWidth datatype='text' type="text"    margin="dense" id="fn"  name="fn" defaultValue={props.person.FirstName + " "+ props.person.LastName }   variant="outlined" />
          <Autocomplete freeSolo disablePortal    id="combo-box" options={topShells} renderInput={(params) => <TextField   {...params} id="Comment" label="Comment"  name="Comment"/>} 
           ListboxProps={{style:{ maxHeight: '120px', border: '1px solid blue'  }}}
          />
          <TextField required autoFocus margin="dense" id="Quantity" label="Quantity" name="Quantity" defaultValue={1} type="Number" fullWidth variant="outlined" />
         
       
          <TextField sx={{width:'0px'}}   margin="dense" id="Date" label="Date" name="Date" defaultValue={normalizeDate(props.rowingSession.Date)} type="hidden" fullWidth variant="standard" />     
          <TextField sx={{width:'0px'}} datatype='Number' required margin="dense" id="Order" name="Order" type="hidden" defaultValue={props.rowingSession.Order} fullWidth variant="standard" />
          <TextField sx={{width:'0px'}} InputProps={{ readOnly: true }} datatype='text'   margin="dense" id="FirstName" name="FirstName" defaultValue={props.person.FirstName} type="hidden" fullWidth variant="standard" />
          <TextField sx={{width:'0px'}}  InputProps={{ readOnly: true }} datatype='text'  margin="dense" id="LastName"  name="LastName" defaultValue={props.person.LastName} type="hidden" fullWidth variant="filled" />


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >Accept</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}